import React, { Component } from 'react'
//import {Link} from 'react-router-dom'

export default class MailingList extends Component {
    render() {
        return (
            <div>
                Test
            </div>
        );
    }
}
